import React from "react";
import cn from "classnames";
import TicketBubble from "./index";
import { AtomicTicket } from "@/components/pages/program/types";
import { useStateValue } from "@/store";
import { useRoute } from "@/utils";
import { useI18n } from "@/plugins/i18n";

type TicketBubbleGroupProps = {
  tickets: AtomicTicket[];
  maxCount: number;
  className?: string;
  classNameTicketBubble?: string;
  showCityShortCode: boolean;
  slug: string;
  canBuy: boolean;
};

const TicketBubbleGroup: React.FC<TicketBubbleGroupProps> = ({
  tickets,
  maxCount,
  className,
  classNameTicketBubble,
  showCityShortCode,
  slug,
  canBuy,
}) => {
  const [{ locale }] = useStateValue();
  const { t } = useI18n();
  const route = useRoute();
  const ticketsCount = tickets.length;

  return (
    <div className={cn("flex flex-wrap", className)}>
      {tickets.map((ticket, i) => {
        if (ticketsCount > maxCount && i === maxCount - 1) {
          return (
            <TicketBubble
              key={i}
              url={route("home", slug)}
              className={classNameTicketBubble}
              canBuy
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: t("all_dates"),
                }}
              />
            </TicketBubble>
          );
        }

        if (ticketsCount > maxCount && i > maxCount - 2) {
          return null;
        }

        return (
          <TicketBubble
            key={i}
            className={classNameTicketBubble}
            datetimeStr={ticket.datetime}
            datetimeISO={ticket.datetimeISO}
            radarioId={ticket.radarioId}
            cityCode={
              showCityShortCode &&
              (locale === "en" ? ticket.cityShortCodeEn : ticket.cityShortCode)
            }
            url={ticket.url}
            canBuy={canBuy}
            soldOut={ticket.soldOut}
          />
        );
      })}
    </div>
  );
};

export default TicketBubbleGroup;
