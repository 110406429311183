import React from "react";
import cn from "classnames";
import { TICKETS_TZ } from "@/constants";
import * as css from "./ticket-bubble.modules.scss";

export type TicketBubbleProps = Partial<{
  url: string;
  radarioId: string;
  datetimeStr: string;
  datetimeISO: string;
  className: string;
  cityCode: string;
  canBuy: boolean;
  soldOut: boolean;
}>;

const TicketBubble: React.FC<TicketBubbleProps> = ({
  url,
  radarioId,
  datetimeStr,
  datetimeISO,
  className,
  cityCode,
  children,
  canBuy,
  soldOut,
}) => {
  const datetimeHTML =
    datetimeStr &&
    datetimeStr
      .split("|")
      .map((str) => str.replace(/(\.,)$/, ","))
      .filter((_, i) => (cityCode ? i !== 1 : true))
      .join("<br />");

  const [mounted, setMounted] = React.useState(false);
  const hasPassed = new Date() >= new Date(`${datetimeISO}${TICKETS_TZ}`);
  const isActive = canBuy && !soldOut && !hasPassed;

  const Component = isActive ? "a" : "div";
  const componentProps = isActive
    ? { href: url || (radarioId ? `#event/${radarioId}` : "#") }
    : {};

  React.useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <div className={cn(className)}>
      <Component
        {...componentProps}
        className={cn(css.ticketLink, `opacity-${mounted ? 100 : 0}`, {
          [css.strikethrough]: hasPassed || soldOut,
        })}
      >
        <span
          className={css.ticketLinkInner}
          dangerouslySetInnerHTML={
            !children
              ? {
                  __html: cityCode
                    ? `${cityCode}<br />${datetimeHTML}`
                    : datetimeHTML,
                }
              : undefined
          }
        >
          {children}
        </span>
      </Component>
    </div>
  );
};
export default TicketBubble;
